//------------------------------------------------------------------------
//------------------------------------------------------------------------
//------------------------------------------------------------------------
// FORM ELEMENTS
//------------------------------------------------------------------------
//------------------------------------------------------------------------
//------------------------------------------------------------------------
@import "settings/settings.scss";
.color-picker {
    position: relative;
}

input, textarea {
    -webkit-appearance: none;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    opacity: 0.5;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
    opacity: 0.5;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    opacity: 0.5;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    opacity: 0.5;
}

.actions {
    text-align: right;
}

form {
    .actions {
        display: flex;
        justify-content: flex-end;

        .action {
            margin: 0 0 0 10px;

            &:first-of-type {
                margin: 0;
            }
        }
    }
}
