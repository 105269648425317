//------------------------------------------------------------------------
//------------------------------------------------------------------------
//------------------------------------------------------------------------
// ICON PATHS AND STYLING
//------------------------------------------------------------------------
//------------------------------------------------------------------------
//------------------------------------------------------------------------
@import "settings/settings.scss";

@font-face {
    font-family: 'map-font';
    src: url('../assets/fonts/mapiconfont/map-font.woff2?52769672') format('woff2');
    font-weight: normal;
    font-style: normal;
}

.ic-hand-vacuum-suction-cup:before { content: '\e800'; } /* '' */
.ic-operator-position-stand-on:before { content: '\e801'; } /* '' */
.ic-action-add-to-calendar:before { content: '\e802'; } /* '' */
.ic-action-add-to-inbox:before { content: '\e803'; } /* '' */
.ic-action-add-to-list:before { content: '\e804'; } /* '' */
.ic-accessory-box-tipper:before { content: '\e805'; } /* '' */
.ic-accessory-fork-positioners:before { content: '\e806'; } /* '' */
.ic-accessory-four-fork-gear:before { content: '\e807'; } /* '' */
.ic-accessory-jib-forklift:before { content: '\e808'; } /* '' */
.ic-accessory-jib-short:before { content: '\e809'; } /* '' */
.ic-boomlift-mast:before { content: '\e80a'; } /* '' */
.ic-container-resto:before { content: '\e80b'; } /* '' */
.ic-engine-battery:before { content: '\e80c'; } /* '' */
.ic-equipment-climbing-and-lifting:before { content: '\e80d'; } /* '' */
.ic-lift-material:before { content: '\e80e'; } /* '' */
.ic-option-tires-non-marking:before { content: '\e80f'; } /* '' */
.ic-tool-bucket:before { content: '\e810'; } /* '' */
.ic-other-generator:before { content: '\e811'; } /* '' */
.ic-tool-ladder:before { content: '\e812'; } /* '' */
.ic-consumable-nails:before { content: '\e813'; } /* '' */
.ic-tool-pump:before { content: '\e814'; } /* '' */
.ic-tool-screwdriver:before { content: '\e815'; } /* '' */
.ic-tool-shovel:before { content: '\e816'; } /* '' */
.ic-consumable-tape:before { content: '\e817'; } /* '' */
.ic-other-toilet:before { content: '\e818'; } /* '' */
.ic-consumable-tiewrap:before { content: '\e819'; } /* '' */
.ic-scaffolding-edge-barrier:before { content: '\e81a'; } /* '' */
.ic-scaffolding-rolling:before { content: '\e81b'; } /* '' */
.ic-scaffolding-room:before { content: '\e81c'; } /* '' */
.ic-transporter-6-seater:before { content: '\e81d'; } /* '' */
.ic-boomlift-telescopic-high-capacity:before { content: '\e81e'; } /* '' */
.ic-other-power-washer:before { content: '\e81f'; } /* '' */
.ic-other-airco:before { content: '\e820'; } /* '' */
.ic-other-safety-item:before { content: '\e821'; } /* '' */
.ic-other-compressor:before { content: '\e822'; } /* '' */
.ic-bicycle-kick-scooter:before { content: '\e823'; } /* '' */
.ic-3d-system:before { content: '\e824'; } /* '' */
.ic-other-light-tower:before { content: '\e825'; } /* '' */
.ic-indoor:before { content: '\e826'; } /* '' */
.ic-outdoor:before { content: '\e827'; } /* '' */
.ic-other-pallethook:before { content: '\e828'; } /* '' */
.ic-operator-position-pedestrian:before { content: '\e829'; } /* '' */
.ic-other-quad:before { content: '\e82a'; } /* '' */
.ic-construction-light:before { content: '\e82b'; } /* '' */
.ic-drywall-lift:before { content: '\e82c'; } /* '' */
.ic-floor-clamp:before { content: '\e82d'; } /* '' */
.ic-working-shoes:before { content: '\e82e'; } /* '' */
.ic-saw:before { content: '\e82f'; } /* '' */
.ic-stop-log:before { content: '\e830'; } /* '' */
.ic-storage-box:before { content: '\e831'; } /* '' */
.ic-transport-trolley:before { content: '\e832'; } /* '' */
.ic-trestle:before { content: '\e833'; } /* '' */
.ic-vacuum-cleaner:before { content: '\e834'; } /* '' */
.ic-walkie-talkie:before { content: '\e835'; } /* '' */
.ic-crawler-mastlift:before { content: '\e836'; } /* '' */
.ic-option-wheels:before { content: '\e837'; } /* '' */
.ic-other:before { content: '\e838'; } /* '' */
.ic-road-mat:before { content: '\e839'; } /* '' */
.ic-safety-harness:before { content: '\e83a'; } /* '' */
.ic-scan-asset:before { content: '\e83b'; } /* '' */
.ic-trailer-car-transport:before { content: '\e83c'; } /* '' */
.ic-trailer-scaffolding:before { content: '\e83d'; } /* '' */
.ic-scaffolding-bumper:before { content: '\e83e'; } /* '' */
.ic-aluminum-stages:before { content: '\e83f'; } /* '' */
.ic-outriggers:before { content: '\e840'; } /* '' */
.ic-lpg-tank:before { content: '\e841'; } /* '' */
.ic-no-outriggers:before { content: '\e842'; } /* '' */
.ic-special-equipment:before { content: '\e843'; } /* '' */
.ic-boomlift-articulating-1:before { content: '\e844'; } /* '' */
.ic-boomlift-articulating-xl:before { content: '\e845'; } /* '' */
.ic-boomlift-articulating-heavy:before { content: '\e846'; } /* '' */
.ic-doc-cancel-order:before { content: '\e84c'; } /* '' */
.ic-doc-credit-note:before { content: '\e84d'; } /* '' */
.ic-doc-damage-report:before { content: '\e84e'; } /* '' */
.ic-doc-delivery-notice:before { content: '\e84f'; } /* '' */
.ic-doc-invoice:before { content: '\e850'; } /* '' */
.ic-doc-ocb:before { content: '\e851'; } /* '' */
.ic-doc-order-confirmation:before { content: '\e852'; } /* '' */
.ic-doc-pickup-notice:before { content: '\e853'; } /* '' */
.ic-doc-rental-end-confirmation:before { content: '\e854'; } /* '' */
.ic-accessory-bb-handler:before { content: '\e855'; } /* '' */
.ic-accessory-extension-forks:before { content: '\e856'; } /* '' */
.ic-accessory-floating-tyne:before { content: '\e857'; } /* '' */
.ic-accessory-glass-lifting-device:before { content: '\e858'; } /* '' */
.ic-accessory-hoist:before { content: '\e859'; } /* '' */
.ic-accessory-hook-searcher:before { content: '\e85a'; } /* '' */
.ic-accessory-hook:before { content: '\e85b'; } /* '' */
.ic-accessory-jib-fly:before { content: '\e85c'; } /* '' */
.ic-accessory-jib-with-hoist:before { content: '\e85d'; } /* '' */
.ic-accessory-jib:before { content: '\e85e'; } /* '' */
.ic-accessory-long-spoons:before { content: '\e85f'; } /* '' */
.ic-accessory-remote-control:before { content: '\e860'; } /* '' */
.ic-accessory-safety-cage-expandable:before { content: '\e861'; } /* '' */
.ic-accessory-safety-cage:before { content: '\e862'; } /* '' */
.ic-accessory-scoop:before { content: '\e863'; } /* '' */
.ic-option-operator-cabin:before { content: '\e864'; } /* '' */
.ic-option-quadruplex-mast:before { content: '\e865'; } /* '' */
.ic-accessory-rotator:before { content: '\e866'; } /* '' */
.ic-action-archive:before { content: '\e867'; } /* '' */
.ic-bicycle-mtb:before { content: '\e868'; } /* '' */
.ic-bicycle-scooter:before { content: '\e869'; } /* '' */
.ic-bicycle-womens-bike:before { content: '\e86a'; } /* '' */
.ic-bicycle:before { content: '\e86b'; } /* '' */
.ic-boomlift-articulating-spider:before { content: '\e86c'; } /* '' */
.ic-boomlift-articulating:before { content: '\e86d'; } /* '' */
.ic-boomlift-mounted-trailer-articulating:before { content: '\e86e'; } /* '' */
.ic-boomlift-mounted-trailer-telescopic:before { content: '\e86f'; } /* '' */
.ic-boomlift-mounted-truck-telescopic:before { content: '\e870'; } /* '' */
.ic-boomlift-mounted-truck:before { content: '\e871'; } /* '' */
.ic-boomlift-mounted-van:before { content: '\e872'; } /* '' */
.ic-boomlift-telescopic-crawler-high-capacity:before { content: '\e873'; } /* '' */
.ic-boomlift-telescopic-crawler:before { content: '\e874'; } /* '' */
.ic-boomlift-telescopic-spider:before { content: '\e875'; } /* '' */
.ic-boomlift-telescopic-trax:before { content: '\e876'; } /* '' */
.ic-boomlift-telescopic:before { content: '\e877'; } /* '' */
.ic-boomlift-vertical:before { content: '\e878'; } /* '' */
.ic-container-cargo:before { content: '\e879'; } /* '' */
.ic-container-office:before { content: '\e87a'; } /* '' */
.ic-crane-crawler-mini:before { content: '\e87b'; } /* '' */
.ic-crane-tower:before { content: '\e87c'; } /* '' */
.ic-crane-telescopic:before { content: '\e87d'; } /* '' */
.ic-detail-capacity:before { content: '\e87e'; } /* '' */
.ic-detail-height:before { content: '\e87f'; } /* '' */
.ic-diesel-tank:before { content: '\e880'; } /* '' */
.ic-detail-width:before { content: '\e881'; } /* '' */
.ic-engine-electrical:before { content: '\e882'; } /* '' */
.ic-engine-hybrid:before { content: '\e883'; } /* '' */
.ic-engine-manual:before { content: '\e884'; } /* '' */
.ic-engine-thermal-diesel:before { content: '\e885'; } /* '' */
.ic-engine-thermal-gas:before { content: '\e886'; } /* '' */
.ic-forklift-rough-terrain:before { content: '\e887'; } /* '' */
.ic-forklift:before { content: '\e888'; } /* '' */
.ic-information:before { content: '\e889'; } /* '' */
.ic-list-orders:before { content: '\e88a'; } /* '' */
.ic-loading-ramp:before { content: '\e88b'; } /* '' */
.ic-mast-type-double:before { content: '\e88c'; } /* '' */
.ic-mast-type-quadriple:before { content: '\e88d'; } /* '' */
.ic-mast-type-single:before { content: '\e88e'; } /* '' */
.ic-mast-type-triple:before { content: '\e88f'; } /* '' */
.ic-option-anti-freeze:before { content: '\e890'; } /* '' */
.ic-option-grass-tyres:before { content: '\e891'; } /* '' */
.ic-option-license-plate:before { content: '\e892'; } /* '' */
.ic-option-low-clearance:before { content: '\e893'; } /* '' */
.ic-option-power-group:before { content: '\e894'; } /* '' */
.ic-option-side-shift:before { content: '\e895'; } /* '' */
.ic-safety-ladder-with-platform:before { content: '\e896'; } /* '' */
.ic-reform-ladder:before { content: '\e897'; } /* '' */
.ic-pushup-ladder:before { content: '\e898'; } /* '' */
.ic-a-ladder:before { content: '\e899'; } /* '' */
.ic-boomlift-telescopic-xl-platform:before { content: '\e89a'; } /* '' */
.ic-scissor-lift-crawler:before { content: '\e89b'; } /* '' */
.ic-scan-person:before { content: '\e89c'; } /* '' */
.ic-scissor-lift:before { content: '\e89d'; } /* '' */
.ic-sideloader:before { content: '\e89e'; } /* '' */
.ic-telescopic-handler-rotating:before { content: '\e89f'; } /* '' */
.ic-telescopic-handler:before { content: '\e8a0'; } /* '' */
.ic-trailer:before { content: '\e8a1'; } /* '' */
.ic-transporter-2-seater:before { content: '\e8a2'; } /* '' */
.ic-transporter-4-seater:before { content: '\e8a3'; } /* '' */
.ic-truck:before { content: '\e8a4'; } /* '' */
.ic-tvh-logo:before { content: '\e8a5'; } /* '' */
.ic-van:before { content: '\e8a6'; } /* '' */
.ic-warehouse-electric-pallettruck:before { content: '\e8a7'; } /* '' */
.ic-warehouse-electric-pallet-stacker:before { content: '\e8a8'; } /* '' */
.ic-warehouse-manual-pallettruck:before { content: '\e8a9'; } /* '' */
.ic-warehouse-reachtruck-seated:before { content: '\e8aa'; } /* '' */

.ic {
    font-family: "map-font";
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 6px;
    text-align: center;

    font-variant: normal;
    text-transform: none;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.large {
        font-size: 26px;
    }

    &.small {
        font-size: 16px;
    }
}
