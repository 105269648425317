%display-grid {
    display: grid;
    width: 100%;
}

@mixin desktop-without-start-end-date-column-view {
    .asset-items-list {
        .scrollable {
            height: inherit;
        }

        &.has-footer .scrollable {
            height: calc(100vh - 147px);
        }

        list-sort-header, .order-header {
            display: none;
        }

        list-sort-menu {
            display: block;
        }

        .list-item {
            .list-item-content-wrapper {
                .list-item-detail-wrapper {
                    &.start-end-date {
                        grid-template-columns: 1fr;
                    }
                }
            }
        }
    }
}

@mixin mobile-view {
    .asset-items-list {
        .list-item {
            .list-item-content-wrapper {
                grid-template-columns: 1fr;
                padding: 26px 0;
                row-gap: 5px;

                .asset-info, .list-item-detail-wrapper {
                    padding: 0;
                }

                .asset-info-wrapper {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    line-height: 108%;
                }
            }
        }
    }
}

.asset-items-list {
    .scrollable {
        height: calc(100vh - 120px);
        overflow: scroll;
        overflow: overlay;
    }

    &.has-tabs .scrollable {
        height: calc(100vh - 155px);
    }

    &.has-footer .scrollable {
        height: calc(100vh - 197px);
    }

    list-sort-header .columns {
        grid-template-columns:
            35% [asset-info-wrapper]
            minmax(15%, 1fr)
            minmax(15%, 1fr)
            repeat(auto-fit, minmax(10%, 1fr));
    }

    .icon-only {
        cursor: pointer;
        display: inline-flex;
        align-items: center;

        &.delete {
            justify-content: center;

            &:hover i {
                color: var(--action-color);
            }
        }
    }

    .list-item {
        border-bottom: 1px solid rgba($color-dark, .1);
        @extend %display-grid;
        min-height: 65px;
        padding: 0 26px;
        cursor: pointer;
        grid-template-columns: 40px 1fr 40px;

        > div {
            display: inline-flex;
            align-items: center;
        }

        .subcategory-icon {
            justify-content: flex-start;
        }

        .list-item-content-wrapper {
            @extend %display-grid;
            grid-template-columns:
                calc(35% - 26px) [asset-info-wrapper]
                minmax(30%, 2fr) [start-end-date]
                repeat(auto-fit, minmax(10%, 1fr));
            font-size: 14px;

            .asset-info-wrapper {
                .asset-info {
                    width: 100%;

                    > div:first-of-type {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        line-height: 110%;
                    }
                }
            }

            .list-item-detail-wrapper {
                &.start-end-date {
                    @extend %display-grid;
                    grid-template-columns: 50% 50%;
                    grid-row-gap: 5px;
                    align-items: center;

                    .dash {
                        visibility: hidden;
                    }
                }
            }

            .asset-info, .list-item-detail-wrapper {
                padding: 26px 0;

                > div {
                    padding-right: 10px;
                }
            }

            .under-item {
                margin-top: 5px;
                font-size: 12px;
            }
        }

        footer {
            height: 77px;
            position: fixed;
            bottom: 0;
            right: 0;
            width: auto;
            background-color: white;
        }
    }
}

mat-sidenav-container.collapsed {
    footer {
        left: 56px;
    }

    @media screen and (max-width: 1000px) {
        @include desktop-without-start-end-date-column-view;
    }

    @media screen and (max-width: 700px) {
        @include mobile-view;
    }
}

mat-sidenav-container:not(.collapsed) {
    footer {
        left: 320px;
    }

    @media screen and (max-width: 1180px) {
        @include desktop-without-start-end-date-column-view;
    }

    @media screen and (max-width: 1000px) {
        @include mobile-view;
    }
}

.platform-tablet, .platform-mobile {
    footer {
        left: 0 !important;
    }

    .asset-items-list.has-footer .scrollable {
        height: calc(100vh - 124px) !important;
    }
}

