@import "settings/settings.scss";

.fadein:not(.invisible) {
    @include animation(fadein_90 2.5s);
}

@include keyframes(fadein_90) {
    0% , 90% {
        opacity: 0.3;
    }
    100% {
        opacity: 1;
    }
}

@include keyframes(fadein_50) {
    0%, 50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
