@use "sass:math";

.flag-icon-background {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.flag-icon {
  @extend .flag-icon-background;

  position: relative;
  display: inline-block;
  width: math.div(4, 3) * 1em;
  line-height: 1em;
  &:before {
    content: '\00a0';
  }
  &.flag-icon-squared {
    width: 1em;
  }
}

.input.icon-left-custom i.flag-icon {
    top: 4px;
    left: 8px;
}

@mixin flag-icon($country) {
  .flag-icon-#{$country} {
    background-image: url('/assets/img/flags//4x3/#{$country}.svg');
  }

}
