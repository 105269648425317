.platform-desktop {
    .popup-bg {
       width: calc(100% - 320px);
    }

    mat-sidenav-container.collapsed {
        .popup-bg {
            width: calc(100% - 60px);
        }
    }
}

popup-dialog {
    z-index: 15;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.popup-bg {
    background-color: rgba(83, 83, 92, 0.5);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 10;
    display: flex;
    align-items: center;

    .popup {
        width: 360px;
        background: $color-light;
        -moz-box-shadow: rgba(83, 83, 92, 0.2) 0 0 6px;
        -webkit-box-shadow: rgba(83, 83, 92, 0.2) 0 0 6px;
        box-shadow: rgba(83, 83, 92, 0.2) 0 0 6px;
        margin: auto;
        position: relative;

        &.large {
            width: 600px;
        }

        .actions {
            padding: 16px;

            &.fullwidth {
                width: 100%;
            }
        }
    }
}

.platform-mobile, .platform-tablet {
    &.popup-bg {
        width: 100%;
        top: 46px;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;

        .popup {
            margin: 0 6px;
            width: calc(100% - 12px);
            height: auto;

            .loader-overlay {
                height: 100%;
            }

            .navbar.mobile {
                height: 46px !important;
                margin-bottom: 2px;
                padding: 0;

                h1 {
                    width: auto;
                    color: $color-dark;
                    margin: 0;
                    padding-left: 0px;
                }

                .actions {
                    padding: 0;
                    float: left;
                    margin: 5px 0 0 4px;

                    .action {
                        padding: 8px 0;
                        margin: 0 0 0 10px;
                        &.icon-only {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}

.asset-detail-popup, .request-detail-popup {
    section formpart:last-of-type > div {
        margin: 0;
    }
}
