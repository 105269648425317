/* ==========================================================================
	Rem and Em Calc
========================================================================== */

$base-rem-em-value: 16px;

/* Convert to unit
========================================================================== */
@function convert-to-unit($unit, $value, $base-value: $base-rem-em-value) {
	$value: strip-unit($value) / strip-unit($base-value) * $unit;

	@if ($value == 0rem or $value == 0em) {
		$value: 0;
	}

	@return $value;
}

/* Rem Calc
========================================================================== */
@function rem-calc($value, $base-value: $base-rem-em-value) {
	@return convert-to-unit(1rem, nth($value, 1), $base-value);
}

/* Em Calc
========================================================================== */
@function em-calc($value, $base-value: $base-rem-em-value) {
	@return convert-to-unit(1em, nth($value, 1), $base-value);
}

@function strip-unit($num) {
    @return $num / ($num * 0 + 1);
}

