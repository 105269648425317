@mixin mobile-view {
    .items-list {
        .scrollable {
            height: inherit;
        }

        list-sort-header {
            display: none;
        }

        list-sort-menu {
            display: block;
        }

        .list-item-content-wrapper {
            grid-template-columns: 1fr;
            row-gap: 5px;
            margin: 15px 0;

            > div:not(.title-wrapper) {
                font-size: 12px;
            }
        }
    }
}

.items-list {
    .scrollable {
        height: calc(100vh - 120px);
        overflow: auto;
        overflow: overlay;
    }

    list-sort-header .columns {
        grid-template-columns: 50% repeat(auto-fit, minmax(15%, 1fr));
    }

    &.has-tabs .scrollable {
        height: calc(100vh - 155px);
    }

    list-item {
        min-height: 65px;
        height: fit-content;
        border-bottom: 1px solid $color-bg;
        cursor: pointer;
        -webkit-transition: 0.1s all;
        -moz-transition: 0.1s all;
        -ms-transition: 0.1s all;
        -o-transition: 0.1s all;
        transition: 0.1s all;
        display: grid;
        grid-template-columns: 60px 1fr 40px;
        padding: 0 26px;

        &:hover {
            background-color: $color-bg;
        }

        div {
            display: inline-flex;
            align-items: center;
            font-size: 14px;

            &.menu-options {
                justify-content: flex-end;
            }
        }

        .list-item-content-wrapper {
            width: 100%;
            display: grid;
            grid-template-columns: calc(50% - 30px) repeat(auto-fit, minmax(15%, 1fr));

            div {
                width: 100%;
                padding-right: 10px;

                .title {
                    font-weight: bold;
                }

                .subtitle {
                    padding-top: 6px;
                    font-size: 12px;
                }
            }
        }
    }
}

mat-sidenav-container.collapsed {
    @media screen and (max-width: 690px) {
        @include mobile-view;
    }
}

mat-sidenav-container:not(.collapsed) {
    @media screen and (max-width: 950px) {
        @include mobile-view;
    }
}
