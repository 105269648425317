@import "../../node_modules/@angular/material/theming";
@import "settings/settings.scss";
@import "icons.scss";
@import "forms.scss";
@import "animations.scss";
@import "material-theme.scss";
@import "flags/flag-icon.scss";
@import "partials/partials.scss";
@import "templates/templates.scss";

* {
    outline-width: 0;
}

label {
    cursor: pointer;
}

body {
    background-color: $color-bg;
    font-family: $font-regular;
    color: $color-dark;
    padding: 0;
    overflow: hidden;
}

ul {
    padding-inline: 0;
    margin-inline: 0;
    margin-block-start: 0;
    margin-block-end: 0;
}

li {
    list-style-type: none;
}

h1, h2, div, p, li {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
}

.platform-desktop {
    &.mobile, .mobile {
        display: none;
    }
}

.platform-mobile, .platform-tablet {
    &.desktop, .desktop {
        display: none;
    }

    orderline-location-map {
        background-size: contain !important;
        background-position-y: bottom;
    }

    .mobile-content {
        width: 100%;
        top: 46px;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        background-color: white;
        overflow-y: auto;
        padding-bottom: 6px;
    }

    @media screen and (min-width: 999px) {
        .column-33 {
            width: 50% !important;
        }
    }
}

#login.container {
    margin-top: 0 !important;
}

.hidden {
    display: none !important;
}

.clear {
    clear: both;
}

p {
    line-height: 1.4em;
}


button {
    font-size: 14px;
    height: 35px;
    line-height: 35px;
    padding: 0px 16px;
    border: 0;
    display: inline-block;

    background-color: #FFFFFF;

    text-align: center;
}

a.action {
    padding: 10px $padding-small 10px $padding-small;
}

a, button {
    cursor: pointer;
    text-decoration: none;
    color: $color-dark;
    font-family: "Work Sans", sans-serif;

    &.action {
        color: $color-light;
        font-size: 14px;
        height: 35px;
        display: inline-block;
        @include box-shadow(rgba($color-dark, .2) 0 3px 6px);

        i {
            margin: 0 10px 0 0;
        }

        &.icon-only {
            width: 35px;
            color: var(--action-color);
            padding: 8px 0;
            text-align: center;

            i {
                font-size: 20px;
                margin: 0;
                width: 100%;
                text-align: center;
            }

            &.back {
                float: left;
                padding-top: 19px;
                margin-right: 16px;
                width: auto;

                i {
                    font-size: 16px;
                }
            }
        }

        &.primary {
            background-color: var(--action-color);
            color: var(--action-text-color);

            &:not(.no-hover) {
                &:hover {
                    background-color: var(--action-hover-color);
                }
            }

            &:focus {
                background-color: var(--action-hover-color);
            }

            &.disabled {
                opacity: 0.5;
                cursor: pointer;
            }
        }

        &.secondary {
            background-color: $color-dark;
            @media (hover: hover) {
                &:hover {
                    background-color: $color-darker;
                }
            }

            &:focus {
                background-color: $color-darker;
            }
        }

        &.round {
            width: 35px;
            padding: 8px 0;
            text-align: center;
            border-radius: 100%;

            i {
                margin: 0;
                font-size: 20px;
            }
        }

        &.custom-icon {
            display: inline-flex;
            align-items: center;
        }

        &.destruct {
            width: 35px;
            @include box-shadow(none);
            background: none;
            border: 1px solid $red;
            color: $red;
            padding: 8px 0;
            text-align: center;

            i {
                margin: 0;
                font-size: 20px
            }
        }

        &.destruct3 {
            border: 1px solid $red;
            color: $red;
        }
    }
}

.info {
    section {
        border-bottom: 1px solid $color-bg;
        padding: 18px 26px 16px 26px;

        &:last-of-type {
            border: none;
        }
    }
}

.bg {
    @include box-shadow(rgba($color-dark, .2) 0 -2px 2px);
}

.bg header h2 {
    font-size: 14px;
    font-weight: 600;
    margin: $padding-small $padding-small - 6 10px $padding-small - 6;
    color: $color-action;
    float: left;
    width: 90%;
    background: $color-bg;
    padding: 6px;
}

.information {
    position: relative;

    ul {
        li {
            padding: 0 0 $padding-small - 4 0;
            float: left;
            width: 100%;

            .left {
                float: left;
                width: 40%;
                font-size: 12px;
                font-weight: 600;
                padding-top: 2px;
                max-width: 125px;
                word-break: break-word;
                hyphens: auto;
            }

            .right {
                float: left;
                width: 60%;
                font-size: 14px;

                &.ellipsis {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    line-height: 108%;
                }

                span {
                    float: left;
                    width: 100%;
                    padding: 0 0 12px 0;
                }
            }

            .multiple-right {
                span {
                    padding: 0 0 6px 0;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    line-height: 100%;
                }
            }
        }
    }

    .action {
        position: absolute;
        right: 0;
        top: 0;
    }
}

.pin {
    width: $pinsize;
    height: $pinsize;
    border-radius: 50% 50% 50% 0;
    background: $color-action;
    transform: rotate(-45deg);

    @include box-shadow(0px 0px 6px rgba($color-dark, .2));

    i {
        transform: rotate(45deg);
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        margin-top: 5px;
    }
}

.status, .statuscolor {
    top: 8px;
    position: relative;
    color: transparent;
    padding: 6px;

    &.fa-circle {
        font-size: 10px;
    }

    &.fa-sign-out {
        font-size: 12px;
        padding: 6px 5px;
    }

    &.fa-exclamation {
        font-size: inherit;
        margin-top: -2px !important;
        margin-left: 2px !important;
        left: -1px;
    }

    &.hidden {
        color: transparent;
    }

    &.grey {
        color: $color-dark;
        opacity: 0.2;
    }

    &.green {
        color: $green;
    }

    &.orange {
        color: $orange;
    }

    &.transparent {
        color: transparent;
    }
}

.platform-mobile, .platform-tablet {
    &.desktop, .desktop {
        display: none;
    }

    asset-header {
        .status {
            padding: 6px 3px;
        }
    }

    asset-status {
        min-width: 15px;
    }
}

asset-header, ul.requests, ul.assets {
    .highlight {
        opacity: 1;
    }

    .mobile-content {
        width: 100%;
        top: 46px;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        background-color: $color-bg;
        overflow-y: auto;
        padding-bottom: 6px;
    }

    @media screen and (min-width: 999px) {
        .column-33 {
            width: 50% !important;
        }
    }
}

.listcontainer {
    width: auto;
    position: relative;

    > div {
        float: left;
        width: 100%;
    }

    .titles {
        height: 25px;
        text-overflow: ellipsis;

        > div {
            text-align: left;
            padding: 7px 5px 7px 0;

            i {
                color: $color-dark;
                padding: 0 6px;
            }

            &:first-of-type, &:last-of-type {
                width: 4%;
            }
        }
    }
}

.titles {
    background-color: $color-bg;
    height: 35px;
    width: 100%;
    cursor: pointer;

    > div {
        float: left;

        padding: 12px 0 0 0;

        font-size: 10px;
        text-align: center;
        color: $color-dark;

        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.scrollable {
    padding: 10px 26px;
    overflow-y: auto;
    height: calc(100vh - 350px);
}

.asset-detail-popup section formpart:last-of-type > div {
    margin: 0 0 16px 0;
}

project-settings, organization-details, member-settings, user-profile-details, subcontractor-details {
    .logo {
        padding-left: 16px;
        cursor: default;

        label {
            float: left;
            width: 40%;
            font-size: 12px;
            font-weight: 600;
            padding: 2px 0 0 0;
            cursor: default;
        }

        icon-or-bubble {
            cursor: default;
        }
    }
}

subcontractor-details, project-details, organization-details {
    .map div div div {
        cursor: default;
    }
}

login {
    loader {
        .showbox {
            padding: 0 !important;
        }

        .loader {
            width: 27px !important;
        }
    }
}

edit-organization-settings .order-settings-contact-person {
    margin-bottom: 16px;

    .formpart {
        margin-bottom: 6px;
    }
}

.fake-button {
    display: inline-block;
    padding: 0 16px;
    height: 35px;
    font-size: 14px;
}

.platform-desktop {
    mat-sidenav-container.collapsed {
        planning-calendar {
            action-menu .backdrop {
                left: 60px;
            }

            loader {
                left: 30px !important;
            }
        }
    }
}

.pac-container {
    margin-top: 8px;
}

.overlay-backdrop {
    background-color: rgba(83, 83, 92, .3);
}

formpart.radio-group {
    mat-radio-button {
        margin: 0 !important;
    }

    label {
        margin-bottom: 2px !important;
    }
}

search-tags-bar > .filter-search-bar > mat-chip-list {
    .mat-chip-list-wrapper {
        flex-wrap: nowrap !important;
    }
}

.option-check {
    margin: 7px 0px 9px 0px;
}

.suffix {
    font-size: 13px;
    padding: 0 !important;
    float: initial !important;
    width: auto !important;
    text-transform: lowercase;

    span {
        text-decoration: underline;
        cursor: pointer;
    }
}

.dragging * {
    cursor: grabbing !important;
    cursor: -moz-grabbing !important;
    cursor: -webkit-grabbing !important;
}

mat-checkbox .ic-information {
    font-size: 17px;
    padding-left: 9px;
}

.fa-eyedropper {
    color: var(--action-color);
}

list-item:hover {
    .fa-ellipsis-v {
        color: var(--action-color);
    }
}

.map .pin {
    background-color: var(--action-color);

    i {
        color: var(--action-text-color);
    }
}

.clear-padding {
    padding: 0 !important;
}

.check-with-info {
    display: flex;
    align-items: center;

    .ic-information {
        font-size: 17px;
        margin-left: 16px;
    }
}

.no-items {
    padding: 30px !important;
    text-align: center !important;
}

.sidenav-chip-list-search {
    padding: 0 16px;
    border-bottom: 1px solid $color-bg;

    .search-icon {
        margin-right: 10px;
        position: relative;
        width: 20px;
        height: 20px;

        .fa-search {
            position: absolute;
            top: 5px;
            cursor: pointer;
            color: $color-dark;

            &:before {
                font-size: 20px;
            }

            &:hover {
                color: var(--action-color);
            }
        }
    }

    .mat-chip-input {
        width: 155px !important;
        flex: 1 0 155px !important;
        font-size: 14px;
        margin-bottom: 8px;
        height: 16px;
    }

    .mat-form-field-flex {
        padding-top: 0 !important;
    }

    .mat-form-field-wrapper {
        padding-bottom: 10px !important;
    }

    .mat-form-field-underline {
        display: none !important;
    }
}
