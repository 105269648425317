.list-view-table {
    table-layout: auto;
    display: table;
    width: 100%;

    tr:first-of-type {
        background: none;
        color: $color-dark;
        height: 35px;
        a {
            color: $color-dark;
            padding: 0;
        }
        th {
            font-size: 10px;
        }
    }

    tr {
        cursor: pointer;
        -webkit-transition: 0.1s all;
        -moz-transition: 0.1s all;
        -ms-transition: 0.1s all;
        -o-transition: 0.1s all;
        transition: 0.1s all;

        td {
            display: table-cell;
            white-space: nowrap;
            border-bottom: 1px solid $color-bg;
            padding: 10px 0;
            height: 65px;
            vertical-align: middle;
            &:first-of-type {
                width: 72px;
                overflow: hidden;
                padding: 8px 16px 6px;
                &.zone-color {
                    text-align: center;
                }
            }
            &:nth-of-type(2) {
                text-align: left;
                padding-left: 20px;

                > div:first-of-type {
                    font-weight: bold;
                    font-size: 14px;
                }
                div:last-of-type {
                    padding-top: 6px;
                    font-size: 12px;
                }
            }

            &:last-of-type {
                padding: 0 12px 0 0 !important;
                width: 52px;
                text-align: right;
            }
        }

        &:hover {
            background-color: $color-bg;
        }

        &:not(:hover) {
            meatballs-menu i {
                color: $color-dark !important;
            }
        }
    }
}

tr.no-items {
    border: none;
    &:hover {
        cursor: default;
        background-color: $color-light;
    }
    td {
        border: none;
        cursor: default;
        padding: 30px !important;
        text-align: center !important;
        white-space: pre-line;
        &:hover {
            background-color: $color-light;
        }
    }
}

div.no-items {
    padding: 30px !important;
    text-align: center !important;
}

.clear-padding {
    padding: 0 !important;
}

.tablecontainer {
    overflow-x: auto;

    table {
        float: left;
        table-layout: fixed;
        tr:first-of-type {
            background-color: $color-dark;
            color: $color-light;
            height: 35px;
            a {
                color: $color-light;
                padding: 6px;
            }
            th {
                font-size: 10px;
            }
        }
        tr:not(first-of-type) {
            border-bottom: 1px solid $color-bg;
            height: 40px;
        }
        &.content {
            width: 1000px;
            min-width: 100%;
            td {
                border-left: 1px solid $color-bg;
                &:first-of-type {
                    border: none;
                }
            }
            td,
            th {
                text-align: center;
            }
        }
    }
}
