//Columns
@use "sass:math";

$sidenav-width: 324px;
$sidenav-collapsed-width: 60px;

mat-sidenav-container:not(.collapsed) {
    .column-33 {
        width: 33.333%;
        float: left;
        padding: 0 math.div($padding-columns, 2) 0 math.div($padding-columns, 2);

        &:last-of-type {
            padding: 0 math.div($padding-columns, 2) 0 math.div($padding-columns, 2);
            @media screen and (max-width: $width-container-mobile + $sidenav-width) {
                padding: 0;
            }
        }
        &:first-of-type {
            padding: 0 math.div($padding-columns, 2) 0 0;
            @media screen and (max-width: $width-container-mobile + $sidenav-width) {
                padding: 0;
            }
        }
        @media screen and (max-width: $width-container-regular + $sidenav-width) {
            width: 50%;
            &:nth-of-type(odd) {
                padding: 0 math.div($padding-columns, 2) 0 0;
            }
            &:nth-of-type(even) {
                padding: 0 math.div($padding-columns, 2) 0 math.div($padding-columns, 2);
            }
        }
        @media screen and (max-width: $width-container-mobile + $sidenav-width) {
            width: 100%;
            padding: 0 !important;
        }
    }

    .column-50 {
        width: 50%;
        float: left;
        padding: 0 math.div($padding-columns, 2) 0 math.div($padding-columns, 2);
        &:last-of-type {
            padding: 0 0 0 math.div($padding-columns, 2);
            @media screen and (max-width: $width-container-mobile + $sidenav-width) {
                padding: 0;
            }
        }
        &:first-of-type {
            padding: 0 math.div($padding-columns, 2) 0 0;
            @media screen and (max-width: $width-container-mobile + $sidenav-width) {
                padding: 0;
            }
        }
        @media screen and (max-width: $width-container-regular + $sidenav-width) {
            width: 50%;
        }
        @media screen and (max-width: $width-container-mobile + $sidenav-width) {
            width: 100%;
            padding: 0;
        }
    }
}

mat-sidenav-container.collapsed {
    .column-33 {
        width: 33.333%;
        float: left;
        padding: 0 math.div($padding-columns, 2) 0 math.div($padding-columns, 2);
        &:last-of-type {
            padding: 0 math.div($padding-columns, 2) 0 math.div($padding-columns, 2);
            @media screen and (max-width: $width-container-mobile + $sidenav-collapsed-width) {
                padding: 0;
            }
        }
        &:first-of-type {
            padding: 0 math.div($padding-columns, 2) 0 0;
            @media screen and (max-width: $width-container-mobile + $sidenav-collapsed-width) {
                padding: 0;
            }
        }
        @media screen and (max-width: $width-container-regular + $sidenav-collapsed-width) {
            width: 50%;
            &:nth-of-type(odd) {
                padding: 0 math.div($padding-columns, 2) 0 0;
            }
            &:nth-of-type(even) {
                padding: 0 math.div($padding-columns, 2) 0 math.div($padding-columns, 2);
            }
        }
        @media screen and (max-width: $width-container-mobile + $sidenav-collapsed-width) {
            width: 100%;
            padding: 0 !important;
        }
    }

    .column-50 {
        width: 50%;
        float: left;
        padding: 0 math.div($padding-columns, 2) 0 math.div($padding-columns, 2);
        &:last-of-type {
            padding: 0 0 0 math.div($padding-columns, 2);
            @media screen and (max-width: $width-container-mobile + $sidenav-collapsed-width) {
                padding: 0;
            }
        }
        &:first-of-type {
            padding: 0 math.div($padding-columns, 2) 0 0;
            @media screen and (max-width: $width-container-mobile + $sidenav-collapsed-width) {
                padding: 0;
            }
        }
        @media screen and (max-width: $width-container-regular + $sidenav-collapsed-width) {
            width: 50%;
        }
        @media screen and (max-width: $width-container-mobile + $sidenav-collapsed-width) {
            width: 100%;
            padding: 0;
        }
    }

}

mat-dialog-container {
    .column-50 {
        float: left;
        width: 50%;
        @media screen and (max-width: 800px) {
            width: 100%;
        }

        &:nth-of-type(odd) {
            padding: 0;
        }
        &:nth-of-type(even) {
            padding: 0 0 0 32px;
            @media screen and (max-width: 800px) {
                padding: 0;
            }
        }
    }
}
