@mixin transition($time) {
    -webkit-transition: $time;
    -moz-transition: $time;
    -ms-transition: $time;
    -o-transition: $time;
    transition: $time;
}

@mixin animation($config) {
    -webkit-animation: $config;
    -moz-animation: $config;
    -ms-animation: $config;
    -o-animation: $config;
    animation: $config;
}

@mixin animation-delay($time) {
    -webkit-animation-delay: $time;
    -moz-animation-delay: $time;
    -ms-animation-delay: $time;
    -o-animation-delay: $time;
    animation-delay: $time;
}

@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }
    @-moz-keyframes #{$name} {
        @content;
    }
    @-ms-keyframes #{$name} {
        @content;
    }
    @keyframes #{$name} {
        @content;
    }
}

@mixin box-shadow($value) {
    -webkit-box-shadow: $value;
    -moz-box-shadow: $value;
    box-shadow: $value;
}

@mixin border-radius($radius) {
    border-radius: $radius;
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
}
