@use '@angular/material' as mat;
@import "settings/settings.scss";

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

$custom-typography: mat.define-legacy-typography-config(
    $font-family: 'Work Sans, sans-serif'
);
@include mat.legacy-typography-hierarchy($custom-typography);
@include mat.all-legacy-component-typographies($custom-typography);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://www.google.com/design/spec/style/color.html
$candy-app-primary: mat.define-palette(mat.$grey-palette, 800);
$candy-app-accent: mat.define-palette(mat.$grey-palette, 800);

// The warn palette is optional (defaults to red).
$candy-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$candy-app-theme: mat.define-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($candy-app-theme);

.mat-fab.mat-accent {
    background-color: var(--action-color);
    color: var(--action-text-color);

    &:hover {
        background-color: var(--action-hover-color);
    }
}



.mat-form-field-ripple {
    background-color: var(--action-color);
}

.mat-form-field {
    &.mat-focused {
        .mat-form-field-ripple {
            background-color: var(--action-color);
        }
    }

    &.mat-form-field-invalid {
        .mat-form-field-ripple {
            background-color: #f44336;
        }
    }
}

mat-form-field {
    width: 100%;

    &.mat-form-field-appearance-legacy {
        .mat-form-field-label {
            color: #424242;

            .mat-form-field-required-marker {
                color: #424242;
            }

            &.mat-form-field-empty {
                color: #9E9E9E;

                .mat-form-field-required-marker {
                    color: #9E9E9E;
                }
            }
        }

        .mat-form-field-underline {
            background-color: #424242;
        }
    }

    &.mat-focused {
        &.mat-form-field-appearance-legacy {
            .mat-form-field-label {
                color: var(--action-color);

                .mat-form-field-required-marker {
                    color: var(--action-color);
                }
            }

            .mat-form-field-underline {
                background-color: var(--action-color);
            }
        }
    }

    &.mat-form-field-invalid {
        &.mat-form-field-appearance-legacy {
            .mat-form-field-label {
                color: #f44336;

                .mat-form-field-required-marker {
                    color: #f44336;
                }
            }

            .mat-form-field-underline {
                background-color: #f44336;
            }
        }
    }

    &.disabled {
        .mat-form-field-label {
            transition: none !important;
        }
        textarea.mat-input-element {
            color: rgba(0, 0, 0, 0.38);
        }
    }
}

.mat-button-focus-overlay {
    background-color: rgba(var(--action-color), 0.12);
}

button.mat-button, button.mat-raised-button, a.mat-button, a.mat-raised-button {
    text-transform: uppercase;
    font-family: "Work Sans", sans-serif;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
    background-color: var(--action-color);
}

mat-checkbox {
    .mat-checkbox-disabled {
        cursor: not-allowed !important;
    }

    .mat-checkbox-label {
        font-family: "Work Sans", sans-serif;
        font-size: 14px;
        line-height: 1.4em !important;
        white-space: initial !important;
    }

    &.small {
        .mat-checkbox-label {
            font-size: 13px;
            min-height: 16px;
        }

        .mat-checkbox-inner-container {
            height: 16px;
            width: 16px !important;
        }
        .mat-checkbox-ripple {
            width: 40px !important;
        }
    }

    &.large {
        .mat-checkbox-label {
            font-size: 16px !important;
        }
        .mat-checkbox-inner-container {
            height: 16px;
            width: 16px !important;
        }
        .mat-checkbox-ripple {
            width: 40px !important;
        }
    }

    &.margin-left {
        margin-left: 10px;
    }

    &.margin-right .mat-checkbox-inner-container {
        margin-right: 16px;
    }

    &.mat-checkbox-disabled .mat-checkbox-background {
        background-color: #B0B0B0 !important;
    }

    &:not(.mat-checkbox-checked):not(.mat-checkbox-indeterminate) .mat-checkbox-background {
        background-color: transparent !important;
    }

    &.nowrap {
        .mat-checkbox-label {
            white-space: nowrap !important;
        }
    }
}

mat-sidenav {
    mat-select {
        .mat-select-value-text {
            color: white;
            font-size: 14px;
            font-weight: 300;
            font-family: "Work Sans", sans-serif;
        }
    }
}

mat-select {
    &.has-icon {
        width: calc(100% - 35px);
    }
}

mat-option {
    &.all {
        border-top: 1px solid $color-bg;

        .mat-option-text {
            font-size: 14px;
            font-weight: 600;
        }
    }
    &.has-icon {
        icon-or-bubble {
            width: 20px;
            position: absolute;
            top: 13px;
            left: 15px;
            border-radius: 2px;
            line-height: 10px;
        }

        label {
            margin-left: 30px;
            font-size: 14px;
        }
    }
    &.small {
        font-size: 14px !important;

        .option-icon.fa {
            min-width: 12px;

            &.fa-circle {
                font-size: 12px;
            }
        }
    }
}

.fab-bottom-right {
    position: fixed !important;
    bottom: 20px;
    right: 20px;

    i {
        font-size: 20px;
    }

    &.fab-edit {
        .mat-button-wrapper {
            padding: 14px 0;
        }
    }
}

.mat-radio-button.mat-accent {
    .mat-radio-inner-circle, &.mat-radio-checked .mat-radio-persistent-ripple {
        background-color: var(--action-color);
    }
}

mat-radio-group {
    mat-radio-button {
        margin: 6px 0;
    }

    .mat-radio-outer-circle, .mat-radio-inner-circle, .mat-radio-container {
        height: 16px;
        width: 16px;
    }

    .mat-radio-label-content {
        font-family: "Work Sans", sans-serif;
        font-size: 14px;
        height: 35px;
        line-height: 35px !important;

        i {
            margin: 0 4px;
        }
    }

    &:not(.mat-radio-checked) .mat-radio-outer-circle {
        border-color: #424242 !important;
    }

    &.margin-left {
        padding-left: 10px;
    }

    &.row {
        width: 100%;
        position: relative;
        display: block;

        mat-radio-button {
            width: 49%;
        }
    }

    &.small {
        margin: 6px 0;

        .mat-radio-label-content {
            height: 18px;
            line-height: 18px !important;
        }
    }
}

.tooltip-sidenav {
    margin-left: 8px !important;
}

.tooltip-shopping-cart {
    margin-left: 0 !important;
}

.tooltip-planning {
    margin-bottom: 0 !important;
}

mat-form-field.disabled {
    pointer-events: none;

    .mat-form-field-underline {
        background-position: 0;
        background-color: transparent;
    }

    .mat-form-field-underline {
        background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.42)), color-stop(33%, rgba(0, 0, 0, 0.42)), color-stop(0%, transparent));
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
        background-size: 4px 1px;
        background-repeat: repeat-x;
    }

    .mat-select-trigger {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: default;
    }

    .mat-select-value, .mat-select-arrow, input {
        color: rgba(0, 0, 0, 0.38);
    }
}

tagbox .mat-form-field-label-wrapper {
    font-size: 16px;
}

shoppingcart-list, tosignout-list {
    .mat-form-field-infix {
        width: auto;
    }

    textarea {
        width: 95% !important;
    }
}

.pac-container {
    margin: 8px 0;

    .pac-item {
        height: 48px;
        padding-top: 8px;
    }
}

snack-bar-container {
    min-width: auto !important;

    .mat-simple-snackbar {
        align-items: center;
    }

    .mat-simple-snackbar-action {
        color: white !important;
        text-transform: uppercase;
    }
}

.mat-step-icon-not-touched {
    background-color: rgba(0, 0, 0, 0.38) !important;
    color: white;
}

mat-vertical-stepper {
    .mat-vertical-stepper-content {
        overflow: initial;
        height: auto !important;
        visibility: inherit !important;

        .mat-vertical-content {
            padding: 0 24px !important;
        }
    }

    .stepper-buttons {
        margin-top: 16px;
    }

    .selected-info {
        margin: 6px 0;
        display: contents;

        li {
            min-height: 28px;

            i {
                margin: 3px 6px 3px 0;
            }
        }
    }

    mat-step-header.cdk-focused {
        background-color: transparent !important;
    }

    .mat-step-header .mat-step-icon {
        background-color: rgba(0,0,0,.38);
        color: white;

        &.mat-step-icon-selected, &.mat-step-icon-state-edit {
            background-color: var(--action-color);
            color: var(--action-text-color);
        }
    }

    &.disableExtras {
        .mat-step:nth-of-type(3) .mat-vertical-stepper-header {
            pointer-events: none;

            .mat-step-label {
                color: rgba(0, 0, 0, 0.54) !important;
            }

            .mat-step-icon {
                opacity: 0.5;
            }
        }
    }
}

.dialog-no-padding, .confirm-dialog {
    mat-dialog-container {
        padding: 0;
        overflow: visible;

        form {
            position: relative;

            .loader-overlay {
                padding-bottom: 5vh;
            }
        }

        header {
            padding: 25px;
            border-bottom: 10px solid $color-bg;
            position: relative;
            z-index: 1;
            display: flex;
            align-items: center;
            height: 55px;

            &.has-location {
                border-bottom: 0;
                @include box-shadow(rgba($color-dark, 0.3) 0 0 20px);
            }

            .fa-arrow-left {
                display: none;
                color: var(--action-color);
            }

            p {
                margin-right: 6px;
                color: var(--action-color);
            }
        }

        .dialog-content {
            max-height: calc(85vh - 144px);
            overflow: auto;
            padding: 16px;

            &.no-padding {
                padding: 0;

                .padding {
                    padding: 16px;
                }
            }
        }

        .actions {
            border-top: 1px solid #EDEDED;
            padding: 16px;
        }
    }
}

.custom-backdrop {
    background-color: rgba(0, 0, 0, 0.32);
}

.platform-mobile {
    .custom-backdrop {
        margin-top: 46px;
        background-color: rgba(255, 255, 255, .75);
    }

    .dialog-no-padding {
        .mat-dialog-container > * {
            height: 100%;
            left: 0;
            position: fixed;
            top: 0;
            width: 100%;
            display: flex;
            flex-direction: column;

            @include animation(fadein_50 .6s);

            loader {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            header {
                height: 46px;
                background-color: white;
                padding: 0;
                @include box-shadow(rgba($color-dark, 0.5) 0 0 20px);
                border-bottom: 0;
                z-index: 1;

                h1 {
                    font-size: 14px;
                    font-weight: 600;
                    padding: 16px;
                    display: inline-block;
                }

                .fa-arrow-left {
                    display: inline-block;
                    padding: 13px;
                    font-size: 20px;
                }
            }

            form {
                height: calc(100% - 46px);
                overflow-y: auto;
                background-color: white;
                display: flex;
                flex-direction: column;

                .dialog-content {
                    height: 100%;
                    max-height: 100% !important;
                }

                .actions {
                    display: block;
                    background-color: white;
                    overflow: hidden;
                    height: 80px;

                    @media (max-width: 500px) {
                        height: 130px;

                        &.single-action {
                            height: 80px;
                        }
                    }
                }
            }
        }
    }
}

.confirm-dialog {
    min-width: intrinsic;
    min-width: -moz-min-content;
    min-width: min-content;
}

.mat-tab-group.mat-primary {
    .mat-ink-bar {
        background-color: var(--action-color);
    }
}

mat-tab-group {
    .mat-tab-label {
        min-width: 50% !important;
    }

    .mat-tab-label-active {
        opacity: 1;
    }

    &.platform-tablet, &.platform-mobile {
        .mat-tab-label {
            min-width: 33% !important;
        }
    }
}

ellipsis-menu {
    .mat-button-wrapper {
        font-size: 18px;
    }
}

tagbox {
    .mat-chip-input {
        width: 10px !important;
        flex: 1  0 10px !important;
    }
}

mat-chip {
    margin: 0 4px 2px 4px !important;
    padding: 3px 12px !important;
    min-height: 24px !important;
}

mat-chip-list {
    input.mat-chip-input {
        width: 155px;
        flex: 1 0 155px;
    }
}

mat-chip-list.custom-chip-style {
    mat-chip {
        color: white !important;
        background-color: #9E9EA3 !important;

        .mat-chip-remove {
            color: white !important;
            opacity: 1 !important;
            padding-top: 2px;
            margin-left: 0;
        }
    }
}

mat-dialog-container, .mat-menu-panel, .mat-datepicker-content, .mat-select-panel {
    border-radius: 2px !important;
}

.mat-raised-button {
    border-radius: 0 !important;
}

.mat-autocomplete-panel {
    border-bottom-left-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
}

.mat-drawer-container {
    background-color: #EDEDED;
}

.mat-drawer-container.no-sidenav {
    background-color: transparent;
}

.mat-autocomplete-panel mat-option:not(.all) {
    .mat-option-text {
        display: inline-flex;
        align-items: center;

        icon-or-bubble, i.option-icon {
            display: flex;
            align-items: center;
            margin-right: 15px;
            min-width: 20px;
        }
    }
}

.mat-autocomplete-panel.multiline mat-option:not(.all) {
    .mat-option-text {
        > div {
            > .label {
                line-height: 20px;
            }
            > .sublabel {
                font-size: 9px;
                line-height: 9px;
            }
        }
    }
}

.mat-form-field-hint-wrapper {
    mat-hint {
        width: 100%;
        height: 100%;
    }
}


mat-progress-bar.mat-accent .mat-progress-bar-primary::after {
    background-color: $green !important;
}

mat-progress-bar.mat-primary .mat-progress-bar-primary::after {
    background-color: $green !important;
}

mat-progress-bar.mat-warn .mat-progress-bar-primary::after {
    background-color: $orange !important;
}

mat-progress-bar .mat-progress-bar-buffer {
    background-color: whitesmoke !important;
}

.mat-form-field-hint-wrapper {
    display: block !important;
}
