//colours
$color-bg: #EDEDED;
$color-seethrough-bg: rgba(#BBBBBB, 0.3);
$color-action: #EDEDED;
$color-action-dark: #B91856;
$color-dark: #53535C;
$color-darker: #3C3C41;
$color-light: #FFFFFF;
$orange: #DCB426;
$yellow: #EFD670;
$yellow-green: #AFB719;
$green: #53A20F;
$green-dark: #29BCA6;
$blue: #58ADCC;
$blue-dark: #6C8DCB;
$purple: #907BBE;
$purple-light: #BF88BF;
$pink: #F58F8F;
$red: #A20E0F;
$red-dark: #A20E0F;
$pinsize: 35px;

//scaffolding
$padding-large: 26px;
$padding-small: 16px;
$padding-columns: 64px;

$width-container-regular: 1000px;
$width-container-large: 1200px;
$width-container-xlarge: 1800px;
$width-container-mobile: 700px;

//browsers
$experimental-support-for-mozilla: true;
$experimental-support-for-webkit: true;
$experimental-support-for-opera: false;
$experimental-support-for-microsoft: true;
$experimental-support-for-khtml: false;
