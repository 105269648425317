.card {
    @include box-shadow(rgba($color-dark, 0.2) 0 0 6px);
    width: calc(100% - 8px);
    margin: 10px 4px 0 4px;
    background: $color-light;
    padding-bottom: 30px;
}

title-bar-content {
    section {
        padding: $padding-large;
        border-bottom: 1px solid $color-bg;
        display: inline-block;
        width: 100%;
        &:last-of-type {
            border: none;
        }
        header {
            width: 100%;
            display: inline-block;
            h1 {
                font-size: 14px;
                font-weight: 600;
                margin: 0 0 16px 0;
                color: var(--action-color);
                float: left;
            }
        }
    }

    header {
        .errormessage {
            color: $red;
            font-size: 12px;
            font-weight: 500;
            float: right;
            padding: 0 0 0 10px;
        }
    }

    .part {
        border-bottom: 1px solid $color-bg;
        padding: $padding-small 0;
        float: left;
        width: 100%;
        &:last-of-type {
            border: none;
            padding: $padding-small 0 0 0;
        }
    }
}

.platform-desktop .card {
    margin: 0;
    > header {
        border-bottom: 10px solid $color-bg;
    }
}

.platform-mobile, .platform-tablet {
    .card {
        margin: 6px auto 0 auto;
        padding-bottom: 20px;
        > header {
            padding-left: $padding-small;
            padding-right: $padding-small;
            h1 {
                margin: 10px 0;
            }
        }
        section {
            padding: $padding-small;
        }
    }
}

.mobile {
    &.card {
        padding-bottom: 16px;
        .actions {
            //float: right;
            width: 100%;
            .action {
                float: left;
            }
            .action:last-of-type {
                float: right;
            }
        }
    }
}
